<template>
  <div>
    <v-card class="mb-5 pa-5">
      <v-row>
        <v-col>
          <v-btn-toggle v-model="desc_sorting">
            <v-btn x-small :value="true">{{ $store.getters.translate("desceding") }}</v-btn>
            <v-btn x-small :value="false">{{ $store.getters.translate("asceding") }}</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col style="text-align: right;">
          <v-btn-toggle v-model="show_all_exams">
            <v-btn x-small :value="true">{{ $store.getters.translate("all_time") }}</v-btn>
            <v-btn x-small :value="false">{{ $store.getters.translate("new_only") }}</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-5 pa-5" v-for="(exam, exam_key) in getRecords" :key="exam_key" :loading="loading || parent_loading">
      <v-toolbar flat>
        <v-toolbar-title>
          <strong>{{ exam.name }}</strong>
        </v-toolbar-title>
        <v-spacer/>
        <v-chip v-if="exam.status && assessor_type === 'examinator'" small>{{ $store.getters.translate(exam.status) }}</v-chip>
        <v-btn v-if="(user_type === 'candidate' || user_type === 'contact') && exam.status === 'result_set' && show_request_re_exam"
               @click="setRequestReExam(exam)" color="primary" x-small>
          {{ $store.getters.translate("request_re_exam") }}
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <base-row
              :label="$store.getters.translate('examination_date')"
              :value="exam.examination_date ? exam.examination_date : '-'"
              :deletable="false"
              :hide_copy="true"/>
            <base-row
              :label="$store.getters.translate('location')"
              :value="exam.location ? exam.location.name : '-'"
              :deletable="false"
              :hide_copy="true"/>
            <base-row
              v-if="exam.location && exam.location.addresses"
              :label="$store.getters.translate('address')"
              :value="$lodash.map(exam.location.addresses, 'name').join(', ')"
              :deletable="false"/>
            <base-row
                v-if="exam.room && exam.room.name"
                :label="$store.getters.translate('room')"
                :value="exam.room.name"
                :deletable="false"/>
            <h1 v-if="user_type === 'candidate' || user_type === 'contact'" class="mt-8 text-center">{{ $store.getters.translate("orders") }}</h1>
            <v-simple-table v-if="user_type === 'candidate' || user_type === 'contact'">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>{{ $store.getters.translate('candidate_name') }}</th>
                  <th style="text-align: center">{{ $store.getters.translate('order_id') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(candidate, index) in exam.candidates" :key="index">
                  <template v-if="user_type === 'contact'">
                    <td>{{ candidate.name }}</td>
                    <td style="text-align: center">{{ exam.name }} /
                      {{ exam.examination_date ? exam.examination_date : '-' }} /
                      {{ candidate.company_ascription ? candidate.company_ascription : '-' }} /
                      {{ candidate.pivot.order_id ? candidate.pivot.order_id : '-' }}</td>
                  </template>
                  <template v-else>
                    <td>{{ candidate.name }}</td>
                    <td style="text-align: center">{{ exam.name }} /
                      {{ exam.examination_date ? exam.examination_date : '-' }} /
                      {{ candidate.financial_name ? candidate.financial_name : '-' }} /
                      {{ candidate.pivot.order_id ? candidate.pivot.order_id : '-' }}</td>
                  </template>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-expansion-panels v-model="panel" flat class="mb-3" tile popout multiple focusable hover>
        <v-expansion-panel v-for="(session, key) in exam.sessions" :key="key">
          <v-expansion-panel-header>
            <span class="primary--text">
              {{ session.name }}
              <small class="ml-1" v-if="assessor_type === 'examinator'">({{ $store.getters.translate(session.status) }})</small>
            </span>
            <v-spacer/>
            <div v-if="user_type === 'candidate'" class="text-right">
              <v-chip v-if="session.exam_candidate_sessions[0] && session.exam_candidate_sessions[0].status !== 'created'" class="mr-1" small>
                {{ $store.getters.translate(session.exam_candidate_sessions[0].status) }}
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-if="info.show_exam_personnel">
              <base-row
                  class="mt-5 ml-3"
                  :label="$store.getters.translate('examinators')"
                  :value="session.examinators.length > 0 ? $lodash.map(session.examinators, 'name').join(' | ') : '-'"
                  :deletable="false"
                  :hide_copy="true"/>
              <base-row
                  class="ml-3"
                  :label="$store.getters.translate('supervisors')"
                  :value="session.supervisors.length > 0 ? $lodash.map(session.supervisors, 'name').join(' | ') : '-'"
                  :deletable="false"
                  :hide_copy="true"/>
            </template>
            <!--EXAMS TABLE-->
            <v-data-table
                :loading="loading"
                :headers="session_headers"
                :items="session.exam_candidate_sessions"
                hide-default-footer
                calculate-widths
                disable-sort>
              <template v-slot:item.from="{ item }">{{ getTime(item.from) }}</template>
              <template v-slot:item.till="{ item }">{{ getTime(item.till) }}</template>
              <template v-if="user_type === 'candidate' || user_type === 'contact'" v-slot:item.result="{ item }">
                <v-chip class="text-center" dark label :color="backgroundColor(item, session.cesuur)">
                  <span style="width: 40px; text-align: center" class="text-center">{{ getResult(item.result) }}</span>
                </v-chip>
              </template>
              <template v-else v-slot:item.result="{ item }">
                <v-text-field
                  v-if="assessor_type === 'examinator'"
                  disabled
                  flat
                  dense
                  single-line
                  dark
                  class="centered-input"
                  style="width: 45px; border-radius: 3px"
                  placeholder="-"
                  v-model="item.result"
                  @change="updateParticipant(item)"
                  :background-color="backgroundColor(item, session.cesuur)"
                  :tabindex="item.name"/>
                <span v-else>{{ item.result }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="assessor_type === 'examinator'">
                  <v-tooltip v-if="!item.result_frozen" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="setAbsent(item)" color="secondary" small text>
                        <v-icon small>mdi-exit-to-app</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $store.getters.translate("absent") }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.status === 'absent'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="removeAbsent(item)" color="secondary" small text>
                        <v-icon small>mdi-redo</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $store.getters.translate("reset") }}</span>
                  </v-tooltip>
                </template>
              </template>
              <template v-slot:item.cesuur>{{ session.cesuur }}</template>
              <template v-slot:item.status="{ item }">
                <v-chip class="text-center" dark label :color="backgroundColor(item, session.cesuur)">
                  <span v-if="item.status === 'created' || !item.status" style="width: 100px; text-align: center" class="text-center"> - </span>
                  <span v-else style="width: 100px; text-align: center" class="text-center">{{ $store.getters.translate(item.status) }}</span>
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small v-if="item.stored" color="success">mdi-check</v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--NOTES TABLE-->
      <v-card flat v-if="user_type === 'assessor' && exam.notes && exam.notes.length > 0">
        <v-card-title>
          {{ $store.getters.translate("documents") }}
        </v-card-title>
        <v-card-text>
          <base-notes
            :notes="exam.notes"
            :user_id="user_id"
            :user_type="user_type"
            :record="exam"
            @change="$emit('get_exams')"/>
        </v-card-text>
      </v-card>
      <!--CERTIFICATES TABLE-->
      <v-card flat v-if="user_type === 'contact' && exam.certificates">
        <v-card-title>{{ $store.getters.translate("certificates") }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="certificate_headers"
            :items="exam.certificates"
            :items-per-page="200"
            :show-expand="false"
            hide-default-footer
            disable-sort>
            <template v-slot:item.updated_at="{ item }">
              <span v-if="item.updated_at">{{ moment(item.updated_at).format("DD-MM-YYYY HH:mm") }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span>{{ $store.getters.translate(item.status) }}</span>
            </template>
            <template v-slot:item.download="{ item }">
              <v-btn v-if="item.file && item.status === 'valid'" @click="download(item.file)" small color="primary">
                {{ $store.getters.translate("download") }}
                <v-icon right small>mdi-download</v-icon>
              </v-btn>
              <small v-else>
                {{ $store.getters.translate("cannot_download_certificate_note_valid") }}
              </small>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
    <span v-if="exams.length === 0">{{ $store.getters.translate("nothing_found") }}</span>
    <request-re-exam modal_name="request_re_exam" @refresh="$emit('get_exams')" ref="request_re_exam" :all_candidates="all_candidates"/>
  </div>
</template>

<script>
import moment from "moment";
import RequestReExam from "./RequestReExam";
import BaseNotes from "./BaseNotes";
import helpFunctions from "../../../../plugins/helpFunctions";

export default {
  name: "BaseExams",
  components: { RequestReExam, BaseNotes },
  props: ["exams", "user_type", "user_id", "assessor_type", "info", "all_candidates", "parent_loading"],
  data() {
    return {
      certificate_headers: [],
      session_headers: [],
      loading: false,
      show_request_re_exam: false,
      desc_sorting: true,
      show_all_exams: false,
      panel: [0,1,2,3]
    };
  },
  created() {
    this.certificate_headers = helpFunctions.getCertificateHeaders(false);
    this.session_headers = helpFunctions.getSessionHeaders(this.user_type, this.info.show_from_till);
    if(process.env.APP_NAME === 'Instituut voor Technische Vakexamens') {
      this.show_request_re_exam = true;
    }
  },
  methods: {
    updateParticipant(item) {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/portal/result", item)
        .then((response) => {
          item.status = response.data.status;
          item.stored = true;
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      return item;
    },
    setRequestReExam(exam) {
      this.$refs.request_re_exam.exam = exam;
      this.$refs.request_re_exam.openModal('request_re_exam');
      this.$refs.request_re_exam.load();
    },
    setAbsent(item) {
      this.loading = true;
      item.absent = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/portal/result", item)
        .then((response) => {
          item.status = response.data.status;
          this.$toasted.success("saved");
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      return item;
    },
    removeAbsent(item) {
      this.loading = true;
      item.absent = false;
      this.$http
        .post(this.$store.getters.appUrl + "v2/portal/result", item)
        .then((response) => {
          item.status = response.data.status;
          this.$toasted.success("saved");
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      return item;
    },
    backgroundColor(item, cesuur) {
      if (item.status === "absent" || !item.result || parseInt(cesuur) === 0) {
        return "grey";
      }
      if (parseInt(cesuur) > parseInt(item.result)) {
        return "#ff7f75";
      }
      if (item.status === "exempt") {
        return "#ff7f75";
      }
      return "#56d65b";
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/portal/file/" + file.id, { responseType: "blob" })
        .then((response) => {
          let blob;
          if(file.content_type === 'text/html') {
            blob = new Blob([response.data], {type: "text/html"});
          }
          else {
            blob = new Blob([response.data], {type: "application/pdf"});
          }
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    getTime(time) {
      if (time) {
        return moment(time, "HH:mm:ss").format("HH:mm");
      }
      return "-";
    },
    getResult(result) {
      if (!result) {
        return "-";
      }
      return result;
    },
  },
  computed: {
    getRecords() {
      return this.exams;
    },
  },
  watch: {
    desc_sorting: {
      handler() {
        this.exams = this.exams.sort((a, b) => {
          a = moment(a["examination_date"], "DD-MM-YYYY").toDate();
          b = moment(b["examination_date"], "DD-MM-YYYY").toDate();
          return a - b;
        });
        if (this.desc_sorting) {
          this.exams.reverse();
        }
      },
    },
    show_all_exams: {
      handler() {
        this.$emit('get_exams', this.show_all_exams);
      },
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
.centered-input {
  position: relative;
  top: 7px;
}
</style>